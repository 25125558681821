import { useCallback, useEffect,useRef,useState }  from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from "classnames";
import commentedId from "components/Helpers/commentedId";
import { clearSelection,ColorType } from "components/Helpers/highlight"
import { selectionToTextReferences } from "components/Helpers/range-serializer";
import { backgroundColors, textColors } from "consts/colors";
import useClickOutside from "hooks/useClickOutside";
import { CommentType,getDefaultComment } from "interfaces/CommentInterface"
import { AppState, useAppDispatch } from "store";
import { addCommentThunk } from "store/commentReducer"

import "./Highlighter.css"

export interface HighlightColor {
    text: ColorType;
    back: ColorType;
}


function Highlighter({callback}) {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation()
    const session = useSelector((s: AppState) => s.session);
    const [state, setState] = useState({
      selectorIsOpened: false
    })
    const [color, setColor] = useState<HighlightColor>({
        text: ColorType.WHITE,
        back: ColorType.RED,
    })

    const highlightSelection = useCallback(() => {
        const selection = window.getSelection()
        if (selection === null) {
            return
        }
        const textRefs = selectionToTextReferences(selection);

        if (textRefs && textRefs.length > 0) {
            dispatch(addCommentThunk({
              ...getDefaultComment(session),
              id: commentedId(),
              type: CommentType.Highlight,
              value: `${color.text}_${color.back}`,
              created: new Date().toISOString(),
              location: pathname,
              textRefs
          }));
        }
        callback()
        clearSelection()
        document.querySelector(".highlighterSelection")?.classList.remove("active")
    }, [color, session])

    const colorSelectorRef = useRef<HTMLDivElement>(null);
    useClickOutside(() => [colorSelectorRef.current], () => setState(s => ({
      ...s,
      selectorIsOpened: false
    })));

    return (
        <div className={classNames("toolbar-item","highlighter", {
          active: state.selectorIsOpened
        })}>
            <div className="highlighter-value">
                <div className='value' style={{
                    backgroundColor: color.back,
                    color: color.text
                  }} onClick={highlightSelection}>
                    A
                </div>
                <div className="chevron" onClick={() => setState(s => ({...s, selectorIsOpened: !s.selectorIsOpened }))}>
                  <ExpandMoreIcon/>
                </div>
            </div>
            {
              state.selectorIsOpened && <div className="highlighter-selector">
                <div ref={colorSelectorRef}>
                    <h6>Текст</h6>
                    {textColors.map((colorType, index) =>
                      <TextColor key={index} callback={() => setColor({ ...color, text: colorType })} textColor={colorType} />)}
                    <h6>Фон</h6>
                    {backgroundColors.map((colorType, index) =>
                      <TextBackColor key={index} dark={true} callback={() => setColor({ ...color, back: colorType })} backColor={colorType} />)}
                  </div>
              </div>
            }
        </div>
    )
}

export function TextColor({ callback, textColor }) {
    return (
        <div className="font-selector color-selector" onClick={callback} style={{
          color: textColor,
        }}>
          Цвет текста...
        </div>
    )
}

export function TextBackColor({ callback, backColor, dark = false }) {
    return (
        <div className="font-selector back-selector" onClick={callback} style={{
          backgroundColor: backColor,
          color: dark?'white':'black',
        }}>
          Цвет фона...
        </div>
    )
}

// const MemorizedTextColor = React.memo(TextColor)

export default Highlighter;
