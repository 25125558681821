import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Button from '@mui/material/Button';
import { ComponentEvents } from "components/Events"
import commentedId from "components/Helpers/commentedId";
import { clearSelection } from "components/Helpers/highlight"
import textRefsBoundingRect from "components/Helpers/textRefsBoundingRect";
import useClickOutside from "hooks/useClickOutside";
import { CommentInterface, getDefaultComment } from "interfaces/CommentInterface"
import { AppState, useAppDispatch } from "store";
import { addCommentThunk } from "store/commentReducer"

import "./CommentForm.css"

const CLASS_NAME = "commentForm"

function CommentForm({left: leftPosition}) {
    const dispatch = useAppDispatch()
    const [textareaValue, setTextareaValue] = useState('');
    const session = useSelector((s: AppState) => s.session);
    const [comment, setComment] = useState<CommentInterface>(getDefaultComment(session))
    const { pathname } = useLocation();

    const areaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      if (areaRef.current) {
        areaRef.current.focus();
      }
    }, [areaRef.current]);

    const onCallCommentForm = useCallback((event: CustomEvent) => {
        document.dispatchEvent(new CustomEvent(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM))
        setComment(event.detail.comment);
        document.querySelector(`.${CLASS_NAME}`)?.classList.add("active")
        clearSelection()
    }, [])

    const onUnwrapComments = useCallback((event: CustomEvent) => {
        document.querySelector(`.${CLASS_NAME}`)?.classList.remove("active")
    }, [])

    useEffect(() => {
        document.addEventListener(ComponentEvents.CALL_COMMENT_FORM, onCallCommentForm as EventListener);
        document.addEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
        return () => {
            document.removeEventListener(ComponentEvents.CALL_COMMENT_FORM, onCallCommentForm as EventListener);
            document.removeEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
        }
    }, [])

    const addComment = useCallback((e) => {
        e.preventDefault()
        dispatch(addCommentThunk({
          ...comment,
          created: new Date().toISOString(),
          id: commentedId(),
        }))
        document.querySelector(`.${CLASS_NAME}`)?.classList.remove("active")
        setComment(getDefaultComment(session))
        setTextareaValue('');
    }, [pathname, comment, session]);

    const boxRef = useRef<HTMLDivElement>(null);

    const close = useCallback(() => {
        document.querySelector(`.${CLASS_NAME}`)?.classList.remove("active")
        setTextareaValue('');
    }, [])

    useClickOutside(() => [boxRef.current], close);

    return (
        <div ref={boxRef} className={CLASS_NAME} style={{
          top: comment.textRefs[0]?.topOffset ?? 0,
          left: (comment.textRefs[0]?.leftOffset ?? 0) - leftPosition - 50
        }}>
            <div>
                <textarea
                    ref={areaRef}
                    value={textareaValue}
                    onChange={e => {
                      setComment({ ...comment, value: e.target.value })
                      setTextareaValue(e.target.value);
                    }}
                    placeholder="Комментарий..."
                    className="commentTextInput"
                ></textarea>
            </div>
            <div className="commentFormActions">
                <Button className="commentFormButton" onClick={addComment}>Добавить</Button>
            </div>
        </div>
    );
}

export default CommentForm;
