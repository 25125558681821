import { getDefaultTextReference,ITextReference } from "interfaces/ITextReference"
import { HIGHLIGHTED_CLASS } from "vars/consts";

export const SELECTORS: string = [
  'body .single-content > .row > div:first-child *',
  'body .news-single_content > .row > div:first-child *'
].join(", ");

interface INodeInfo {
	node: Node | null;
	parentSelector: string;
	startPos: number;
	endPos: number;
}

function _getDefaultNodeInfo(): INodeInfo {
	return {
		node: null,
		parentSelector: "",
		startPos: 0,
		endPos: 0,
	}
}

function computedNthIndex(node: Node): number {
	let result = 0
	if (node.parentNode === null) {
		return result
	}
	const childNodes: Node[] = [...node.parentNode.childNodes].map((cn) => cn as Node)
	let elementsWithSameTag = 0
	for (let i = 0; i < childNodes.length; i++) {
		if (childNodes[i] === node) {
			result = elementsWithSameTag + 1
			break
		}
		if (childNodes[i].nodeName === node.nodeName) {
			elementsWithSameTag++;
		}
	}
	return result
}

export function childNodeIndexOf(parentNode: Node, childNode: Node): number {
	const index = [...parentNode.childNodes].map((cn) => cn as Node).indexOf(childNode)
	return index !== -1 ? index : 0
}

function generateSelector(node: Node | null): string {
	let currentNode: Node | null = node
	const tagNames: string[] = []
	while (currentNode) {
		let tagName: string | undefined = currentNode.nodeName;
		if (tagName[0] === "#" || (currentNode as Element).className === HIGHLIGHTED_CLASS) {
			tagName = undefined
		}
		if (tagName) {
			const nthIndex: number = computedNthIndex(currentNode);
			let selector = tagName
			if (nthIndex > 1) {
				selector += ":nth-of-type(" + nthIndex + ")";
			}
			tagNames.push(selector);
		}
		currentNode = currentNode.parentNode;
	}
	return tagNames.reverse().join(" > ").toLowerCase()
}


export function selectionToTextReferences(selection: Selection): ITextReference[] {
	const selectionRange: Range = selection.getRangeAt(0)
	const rect: DOMRect = selectionRange.getBoundingClientRect()

	const safeNodesInfo: INodeInfo[] = getSafeNodes(selectionRange)

	for (let i = 0; i < safeNodesInfo.length; i++) {
		let node = safeNodesInfo[i].node
		if (node === null) {
			continue
		}
		const parentElement = node.parentNode as Element
		if (parentElement.className === HIGHLIGHTED_CLASS) {
			node = node.parentNode as Node
		}
		if (node.parentNode === null) {
			continue
		}
		const childNodes = [...node.parentNode.childNodes].map((cn) => cn as Node)

		let shift = 0
		const startIndex = childNodes.indexOf(node)
		for (let i = 0; i < startIndex; i++) {
			const value = childNodes[i].textContent?.length
			shift += value != undefined ? value : 0
		}
		safeNodesInfo[i].startPos += shift
		safeNodesInfo[i].endPos += shift
		safeNodesInfo[i].parentSelector = generateSelector(node)
	}

	// for (let i = safeNodesInfo.length - 1; i > 0; i--) {
	//     if (safeNodesInfo[i].parentSelector === safeNodesInfo[i - 1].parentSelector &&
	//         safeNodesInfo[i].startPos === safeNodesInfo[i - 1].endPos) {
	//         safeNodesInfo[i - 1].endPos = safeNodesInfo[i].endPos
	//         safeNodesInfo.splice(i, 1)
	//     }
	// }

	const textReferences: ITextReference[] = safeNodesInfo.map((ni: INodeInfo) => {
		return {
			...getDefaultTextReference(),
			id: Date.now().toString(),
			topOffset: rect.top + rect.height + window.scrollY,
			leftOffset: rect.left + rect.width / 2,
			value: selection.toString().trim(),
			parentSelector: ni.parentSelector,
			startPos: ni.startPos,
			endPos: ni.endPos,
		}
	})
	return textReferences
}

function getSafeNodes(range: Range): INodeInfo[] {
	const allNodesInRange: Node[] = getRangeNodes(range.startContainer, range.endContainer)
	const allNodesInfoInRange: INodeInfo[] = getNodesInfo(allNodesInRange, range.startOffset, range.endOffset)
	const filtredNodesInfo: INodeInfo[] = filterNodesInfo(allNodesInfoInRange)
	for (let i = 0; i < filtredNodesInfo.length; i++) {
		filtredNodesInfo[i].parentSelector = generateSelector(filtredNodesInfo[i].node)
	}
	return filtredNodesInfo
}

function getRangeNodes(startNode: Node, endNode: Node): Node[] {
	if (startNode == endNode && startNode.childNodes.length === 0) {
		return [startNode]
	}
	const nodes: Node[] = []
	let currentNode: Node | null = startNode
	let result: Node | null = null
	let skipChildren = false

	while (currentNode !== null && currentNode !== endNode) {
		if (!nodes.includes(currentNode)) {
			nodes.push(currentNode)
		}
		result = null
		skipChildren = currentNode.lastChild !== null && nodes.includes(currentNode.lastChild)
		if (!skipChildren && currentNode.firstChild !== null && !nodes.includes(currentNode.firstChild)) {
			result = currentNode.firstChild
		}
		result = result || currentNode.nextSibling || currentNode.parentNode
		currentNode = result
	}
	if (!nodes.includes(endNode)) {
		nodes.push(endNode)
	}
	return nodes
}

function getNodesInfo(nodes: Node[], start: number, end: number): INodeInfo[] {
	const result: INodeInfo[] = []
	for (let i = 0; i < nodes.length; i++) {
		const nodeInfo = _getDefaultNodeInfo();
		nodeInfo.node = nodes[i]
		nodeInfo.startPos = 0
		const endPos = nodes[i].textContent?.length
		nodeInfo.endPos = endPos != undefined ? endPos : 0
		if (i === 0) {
			nodeInfo.startPos = start
		}
		if (i === nodes.length - 1) {
			nodeInfo.endPos = end
		}
		if (nodeInfo.startPos !== nodeInfo.endPos) {
			result.push(nodeInfo)
		}
	}
	return result
}

function filterNodesInfo(nodesInfo: INodeInfo[]): INodeInfo[] {
	const result: INodeInfo[] = []
	for (let i = 0; i < nodesInfo.length; i++) {
		const node = nodesInfo[i].node
		if (node === null || node.parentNode === null) {
			continue
		}
		const parentElement = node.parentNode as Element
		if (node.nodeType === Node.TEXT_NODE && parentElement.matches(SELECTORS)) {
			result.push(nodesInfo[i])
		}
	}
	return result
}

export function hasForeignNodes(selection: Selection): boolean {
  const range = selection.getRangeAt(0);
  const nodesInRange: Node[] = getRangeNodes(range.startContainer, range.endContainer)
	const nodesInfo: INodeInfo[] = getNodesInfo(nodesInRange, range.startOffset, range.endOffset)
	for (let i = 0; i < nodesInfo.length; i++) {
		const node = nodesInfo[i].node
		if (node === null || node.parentNode === null) {
			continue
		}
		const parentElement = node.parentNode as Element
		if (node.nodeType === Node.TEXT_NODE && !parentElement.matches(SELECTORS)) {
			return true;
		}
	}
	return false
}
