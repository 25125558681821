import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import classNames from "classnames";
import { ComponentEvents } from "components/Events"
import { selectionToTextReferences } from "components/Helpers/range-serializer";
import { CommentInterface, CommentType, getDefaultComment } from "interfaces/CommentInterface"
import { AppState } from "store";

import "./CommentButton.css"

function CommentButton({ callback, commentType }) {
    const { pathname } = useLocation()
    const session = useSelector((s: AppState) => s.session);

    const callCommentForm = useCallback(() => {
        const selection = window.getSelection();
        if (selection === null || selection.toString() === '') {
            return
        }
        const textRefs = selectionToTextReferences(selection);
        if (textRefs && textRefs.length > 0) {
          const comment: CommentInterface = {
            ...getDefaultComment(session),
            id: `temp_${Date.now()}`,
            type: commentType,
            location: pathname,
            textRefs,
          } as CommentInterface;
          document.dispatchEvent(new CustomEvent(ComponentEvents.CALL_COMMENT_FORM, { detail: { comment: comment } }))
        }
        callback();
    }, [])

    return (
      <div className="toolbar-item">
        <button className={ classNames('commentButton', {
          comment: commentType === CommentType.Comment,
          quote: commentType === CommentType.Quote,
        }) } onClick={ callCommentForm } >
          {
            commentType === CommentType.Comment && <>
              <MessageOutlinedIcon />
              &nbsp;
              Комментировать
            </>
          }
          {
            commentType === CommentType.Quote && <>
              <FormatQuoteIcon />
              &nbsp;
              Заметка
            </>
          }

        </button>
      </div>
    )
}

export default CommentButton
