import React, { useCallback, useEffect, useRef, useState }  from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from "classnames";
import commentedId from "components/Helpers/commentedId";
import { clearSelection } from "components/Helpers/highlight"
import { selectionToTextReferences } from "components/Helpers/range-serializer";
import useClickOutside from "hooks/useClickOutside";
import { CommentInterface, CommentType,getDefaultComment } from "interfaces/CommentInterface"
import { AppState, useAppDispatch } from "store";
import { addCommentThunk } from "store/commentReducer"

import EMOJI from './emoji.json'

import "./EmojiSelector.css"

function EmojiSelector({callback}) {
    const dispatch = useAppDispatch()
    const [state, setState] = useState({
      selectorIsOpened: false
    })
    const {pathname} = useLocation()
    const session = useSelector((s: AppState) => s.session);

    const [emojiCharacter, setEmojiCharacter] = useState<string>(`${EMOJI[207].character}`)

    const saveComment = useCallback(() => {
        const selection = window.getSelection()
        if (selection === null) {
            return
        }
        const textRefs = selectionToTextReferences(selection);
        if (textRefs && textRefs.length > 0) {
          dispatch(addCommentThunk({
            ...getDefaultComment(session),
            id: commentedId(),
            type: CommentType.Emoji,
            value: emojiCharacter,
            created: new Date().toISOString(),
            location: pathname,
            textRefs,
          }));
        }
        callback()
        clearSelection()
    }, [emojiCharacter])


    useEffect(() => {
        function changeEmojiState() {
            document.querySelector(".emojiList")?.classList.toggle("active")
        }
        const selectorButton = document.querySelector(".selectorButton")
        selectorButton?.addEventListener("click", changeEmojiState)
        return () => {
            selectorButton?.removeEventListener("onclick", changeEmojiState);
        }
    }, [])

    const emojiSelectorRef = useRef<HTMLDivElement>(null);
    useClickOutside(() => [emojiSelectorRef.current], () => setState(s => ({
      ...s,
      selectorIsOpened: false
    })));

    return (
        <div className={classNames("toolbar-item","emoji-reaction", {
          active: state.selectorIsOpened
        })}>
            <div className="emoji-reaction-value">
                <div className='value' style={{}} onClick={saveComment}>
                  {emojiCharacter}
                </div>
                <div className="chevron" onClick={() => setState(s => ({...s, selectorIsOpened: !s.selectorIsOpened }))}>
                  <ExpandMoreIcon/>
                </div>
            </div>
            {
              state.selectorIsOpened && <div ref={emojiSelectorRef} className="emoji-reaction-selector">
                <EmojiListMemorized callback={(emoji) => setEmojiCharacter(emoji.character)} />
              </div>
            }
        </div>
    );
}

function EmojiList({ callback }) {
    return (
        <div className="emojiList">
            {EMOJI.map((emoji) => <div className="emoji" key={emoji.slug} onClick={() => callback(emoji)}>{emoji.character}</div>)}
        </div>
    )
}

const EmojiListMemorized = React.memo(EmojiList)

export default EmojiSelector
