import { SessionData } from "store";

import { ITextReference } from "./ITextReference"

export enum CommentType {
  Unknown = 'Unknown',
  Comment = 'Comment',
  Emoji = 'Emoji',
  Highlight = 'Highlight',
  Note = 'Note',
  Quote = 'Quote',
}

export interface CommentInterface {
    type: CommentType;
    id: string;
    created: string;
    value: string;
    resolveStatus: boolean;
    location: string;
    user: {
      login: string;
      name: string;
    };
    textRefs: ITextReference[];
    saved: boolean;
}

export interface CommentIndex {
  id: string;
  ts: string;
  type: string;
  page: string;
}

export function getDefaultComment(user: SessionData): CommentInterface {
  const nameParts = [] as string[];
  if (user.first_name_ru) {
    nameParts.push(user.first_name_ru);
    if (user.last_name_ru) {
      nameParts.push(user.last_name_ru);
    }
  } else if (user.first_name_en) {
    nameParts.push(user.first_name_en);
    if (user.last_name_en) {
      nameParts.push(user.last_name_en);
    }
  } else {
    nameParts.push(user.username)
  }
  return {
    user: {
      login: user.username,
      name: nameParts.map((n) => n.trim()).join(" "),
    },
    id: "",
    created: new Date(0).toISOString(),
    type: CommentType.Unknown,
    value: "",
    resolveStatus: false,
    location: "",
    textRefs: [],
    saved: false,
  }
}
