import { useCallback, useEffect } from "react"

export default (elsF: () => (HTMLElement | undefined | null)[], cb: () => void) => {
  const onclick = useCallback((ev: PointerEvent) => {
    const els = elsF().filter(Boolean);
    if (els.length === 0) {
      return;
    }
    let clickTarget = ev.target as HTMLElement | null;
    while(clickTarget) {
      if (els.includes(clickTarget)) {
        return;
      }
      clickTarget = clickTarget.parentElement;
    }
    cb();
  }, [elsF, cb]);

  useEffect(() => {
    document.addEventListener('pointerdown', onclick);
    return () => {
      document.removeEventListener('pointerdown', onclick);
    }
  }, [onclick])
}
