import { useCallback, useEffect, useRef, useState }  from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Button } from "@mui/material";
import commentedId from "components/Helpers/commentedId";
import { CommentInterface, getDefaultComment } from "interfaces/CommentInterface";
import { AppState, useAppDispatch } from "store";
import { addCommentThunk } from "store/commentReducer"

import "./ResponseForm.css"

function ResponseForm({initComment, callback}) {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation();
    const session = useSelector((s: AppState) => s.session);
    const defaultComent: CommentInterface = getDefaultComment(session)
    const [comment, setComment] = useState<CommentInterface>({
      ...initComment,
      user: {...defaultComent.user},
      value: defaultComent.value,
    })

    const areaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      if (areaRef.current) {
        areaRef.current.focus();
      }
    }, [areaRef.current]);

    const addComment = useCallback((e) => {
        e.preventDefault()
        dispatch(addCommentThunk({
          ...comment,
          id: commentedId(),
          created: new Date().toISOString(),
        }))
        setComment(getDefaultComment(session));
        callback()
    }, [pathname, comment])

    return (
        <div className="responseForm">
            <div>
                <textarea
                    ref={areaRef}
                    defaultValue={comment.value}
                    onChange={e => setComment({...comment, value: e.target.value})}
                    placeholder="Комментарий..."
                    className="responseTextInput"
                ></textarea>
            </div>
            <div className="responseFormActions">
                {/* <button className="responseFormButton" onClick={callback}>Закрыть</button> */}
                <Button className="responseFormButton" onClick={addComment}>Ответить</Button>
            </div>
        </div>
    );
}

export default ResponseForm;
