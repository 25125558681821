import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import classNames from "classnames";
import { CommentInterface, CommentType } from "interfaces/CommentInterface";
import { AppState, useAppDispatch } from "store";
import { removeCommentAction, updateCommentAction } from "store/commentReducer"

import "./CommentItem.css"

function CommentItem({comment}: {comment: CommentInterface}) {
    const dispatch = useAppDispatch()

    const [editing, setEditing] = useState<boolean>(false)
    const [newText, setNewText] = useState<string>(comment.value)

    const applyTextChanges = useCallback(() => {
        dispatch(updateCommentAction({
          ...comment,
          value: newText
        }));
        setEditing(false)
    }, [newText])

    const removeComment = useCallback(() => {
        dispatch(removeCommentAction(comment))
    }, [])

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      if (editing && textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [editing])

    const thisUser = useSelector((s: AppState) => s.session.username === comment.user.login);
    const canEdit = [
      CommentType.Comment,
      CommentType.Note,
      CommentType.Quote
    ].indexOf(comment.type) !== -1;

    return (
        <div className={classNames("commentItem", { thisUser })} id={comment.id}>
            <div className="commentItemBlock">
                <div className="commentItemTitle" style={{fontWeight: "bold"}}>@{ thisUser? "Вы":comment.user.name}</div>
                { thisUser && !editing &&
                  <>
                    {canEdit && <div className="commentItemButton edit" onClick={() => setEditing(true)}><EditIcon/></div>}
                    <div className="commentItemButton remove" onClick={removeComment}><DeleteIcon/></div>
                  </>
                }
                { thisUser && editing &&
                  <>
                    <div className="commentItemButton save" onClick={applyTextChanges}><DoneIcon/></div>
                  </>
                }
            </div>
            <div className="commentItemBlock">
                {
                  (thisUser && editing && canEdit)
                    ?
                    <textarea ref={textareaRef} className="commentItemTextInput" defaultValue={newText} onChange={e => setNewText(e.target.value)} ></textarea>
                    :
                    <div className="commentItemText">{newText}</div>
                  }
            </div>
        </div>
    );
}
export default CommentItem;
