import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CommentIndex, CommentInterface, CommentType } from "interfaces/CommentInterface";
import { sessionKey } from "vars/consts";

export const getCommentsInitialState = () => {
  return {
    comments: [] as CommentInterface[],
    index: [] as CommentIndex[],
  };
}

export const fetchCommentsIndexThunk = createAsyncThunk<CommentIndex[], void, {
  rejectValue: Error,
}>
(`session/fetchIndexThunk`, async (_, thunkApi) => {
      const req = await fetch('/plugin/api/comments/index');
      if (req.ok) {
        const ret = await req.json();
        return ret.data.index;
      } else {
        return thunkApi.rejectWithValue(new Error(""))
      }
});

export const addCommentThunk = createAsyncThunk<CommentInterface, CommentInterface, {
  rejectValue: Error,
}>
(`session/addCommentThunk`, async (comment, thunkApi) => {
      if (comment.type === CommentType.Unknown) {
        return thunkApi.rejectWithValue(new Error("")) // Value Error
      }
      const req = await fetch(`/plugin/api/comments/?sk=${sessionKey}`, {
        method: "POST",
        body: JSON.stringify(comment),
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (req.ok) {
        const ret = await req.json();
        console.log('created: ', ret);
        return ret.data as CommentInterface;
      } else {
        return thunkApi.rejectWithValue(new Error("")) // Value Error
      }
});

export const updateCommentThunk = createAsyncThunk<CommentInterface, CommentInterface, {
  rejectValue: Error,
}>
(`session/updateCommentThunk`, async (comment, thunkApi) => {
      if (comment.type === CommentType.Unknown) {
        return thunkApi.rejectWithValue(new Error("")) // Value Error
      }
      const req = await fetch(`/plugin/api/comments/${comment.id}?sk=${sessionKey}`, {
        method: "POST",
        body: JSON.stringify(comment),
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (req.ok) {
        const ret = await req.json();
        console.log('updated: ', ret);
        return ret.data as CommentInterface;
      } else {
        return thunkApi.rejectWithValue(new Error("")) // Value Error
      }
});

export const removeCommentThunk = createAsyncThunk<CommentInterface, CommentInterface, {
  rejectValue: Error,
}>
(`session/deleteCommentThunk`, async (comment, thunkApi) => {
      const req = await fetch(`/plugin/api/comments/${comment.id}?sk=${sessionKey}`, {
        method: "DELETE",
        body: JSON.stringify(comment),
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (req.ok) {
        const ret = await req.json();
        console.log('deleted: ', ret);
        return ret.data as CommentInterface;
      } else {
        return thunkApi.rejectWithValue(new Error("")) // Value Error
      }
});

export const fetchCommentsAction = createAsyncThunk<CommentInterface[], void, {
  rejectValue: Error,
}>
(`comments/fetchCommentsThunk`, async (_, thunkApi) => {
      const req = await fetch('/plugin/api/comments/', {
        method: "GET",
      });
      if (req.ok) {
        const ret = await req.json();
        console.log('fetched: ', ret);
        return ret.data;
      } else {
        return thunkApi.rejectWithValue(new Error(""))
      }
});

export const fetchCommentAction = createAsyncThunk<CommentInterface, string, {
  rejectValue: Error,
}>
(`comments/fetchCommentThunk`, async (id, thunkApi) => {
      const req = await fetch(`/plugin/api/comments/${id}`, {
        method: "GET",
      });
      if (req.ok) {
        const ret = await req.json();
        console.log('fetched: ', ret);
        return ret.data;
      } else {
        return thunkApi.rejectWithValue(new Error(""))
      }
});

const comments = createSlice({
  name: 'comments',
  initialState: getCommentsInitialState(),
  reducers: {
    setCommentIndexAction(state, action: { payload: CommentIndex }) {
      return {
        ...state,
        index: [
          ...state.index,
          action.payload
        ]
      };
    },
    setCommentAction(state, action: { payload: CommentInterface }) {
      return {
        ...state,
        comments: [
          ...state.comments,
          {
            ...action.payload,
            saved: false,
          }
        ] as CommentInterface[]
      };
    },
    removeLocalCommentAction(state, action: { payload: CommentInterface }) {
      return {
        ...state,
        comments: state.comments.filter(c => c.id !== action.payload.id)
      };
    },
    updateCommentsResolveStatus(state, action) {
      return {
        ...state,
        comments: state.comments.map(
            (comment) => comment.textRefs[0]?.id === action.payload.textId ?
                {...comment, resolveStatus: !comment.resolveStatus}
                : comment
        )
      }
    },
    updateCommentValue(state, action: { payload: { comment: CommentInterface, value: string } }) {
      return {
        ...state,
        comments: state.comments.map(
            (comment) => comment.id === action.payload.comment.id ?
                {...comment, value: action.payload.value}
                : comment
          )
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchCommentsIndexThunk.fulfilled, (state, { payload }) => {
      return {
        ...state,
        index: payload
      };
    });
    builder.addCase(addCommentThunk.fulfilled, (state, { payload }) => {
      if (payload.type === CommentType.Unknown) {
        return state;
      }
      return {
        ...state,
        comments: [
          ...state.comments,
          {
            ...payload,
            saved: true,
          }
        ]
      };
    });
    builder.addCase(updateCommentThunk.fulfilled, (state,{ payload }) => {
      if (payload.type === CommentType.Unknown) {
        return state;
      }
      return {
        ...state,
        comments: state.comments.map(
            (comment) => comment.id === payload.id ?
                {
                  ...comment,
                  value: payload.value
                } : comment
          )
      }
    });
    builder.addCase(removeCommentThunk.fulfilled, (state,{ meta: { arg } }) => {
      return {
        ...state,
        comments: state.comments.filter(c => c.id !== arg.id)
      };
    });
    builder.addCase(fetchCommentsAction.fulfilled, (state, { payload }) => {
      return {
        ...state,
        comments: [
          ...(state.comments).filter((c) => c.type !== CommentType.Unknown),
          ...(payload).filter((c) => c.type !== CommentType.Unknown),
        ]
      };
    });
    builder.addCase(fetchCommentAction.fulfilled, (state, { payload }) => {
      if (payload.type === CommentType.Unknown) {
        return state;
      }
      return {
        ...state,
        comments: [
          ...state.comments.filter((c) => c.id !== payload.id && c.type !== CommentType.Unknown),
          payload,
        ]
      };
    });
  },
});

export default comments.reducer;
export const {
  setCommentIndexAction,
  setCommentAction,
  updateCommentsResolveStatus,
  updateCommentValue,
  removeLocalCommentAction,
} = comments.actions;

export const removeCommentAction = removeCommentThunk;
export const updateCommentAction = updateCommentThunk;
