import {render} from 'react-dom';
import {Provider} from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import {store} from "./store"

const root = document.createElement('div');
document.body.appendChild(root);

setTimeout(() => {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    root
  );
},0);
