import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom"
import { useSelector } from "react-redux";

import classNames from "classnames";
import { hasForeignNodes } from "components/Helpers/range-serializer";
import useClickInside from "hooks/useClickInside";
import useClickOutside from "hooks/useClickOutside";
import { CommentType } from "interfaces/CommentInterface";
import { debounce } from 'lodash';
import { AppState } from "store";

import CommentButton from "./CommentButton/CommentButton"
import EmojiSelector from "./EmojiSelector/EmojiSelector";
import Highlighter from "./Highlighter/Highlighter"

import "./Toolbar.css"

function Toolbar() {
  const boxRef = useRef<HTMLDivElement>(null);
  const [toolbarState, setToolbarState] = useState({
    selection: null as ReturnType<typeof window.getSelection> | null,
    active: false,
    top: 0,
    left: 0,
  })
  const authorizedUser = useSelector<AppState>(s => s.session.authorized);

  const onSelect = useCallback((ev) => {
    const selection = window.getSelection();
    // if (toolbarState.)
    if (selection !== null && selection.toString() !== '') {
      if (toolbarState.active) {
        hideToolbar();
      }
      showToolbar(selection);
      if(ev.type === 'pointerup') {
        ev.preventDefault();
      }
    }
  },[toolbarState.active]);

  useEffect(() => {
    if (authorizedUser) {
      document.addEventListener("pointerup", onSelect);
      document.addEventListener("keyup", onSelect);
      window.addEventListener("resize", onSelect);
    }
    return () => {
      if (authorizedUser) {
        document.removeEventListener("pointerup", onSelect);
        document.removeEventListener("keup", onSelect);
        window.removeEventListener("resize", onSelect);
      }
    }
  }, [authorizedUser]);

  const showToolbar = useCallback((selection: Selection) => {
    if (hasForeignNodes(selection)) {
      return;
    }
    const getRange = selection.getRangeAt(0)
    const selectionRect = getRange.getBoundingClientRect()
    setToolbarState({
      active: true,
      selection,
      top: selectionRect.top + window.scrollY - 45,
      left: selectionRect.left
    })
  }, [])

  const hideToolbar = useCallback(() => {
    setToolbarState((s) => ({
      ...s,
      active: false
    }))
  }, []);

  useClickOutside(() => [boxRef.current], hideToolbar);
  useClickInside(() => [boxRef.current], (ev) => ev.preventDefault());

  if (!toolbarState.active) {
    return <></>;
  }

  return (
    createPortal(
      <div ref={boxRef} className={classNames("toolbar", {
        active: toolbarState.active,
      })} style={{
        top: toolbarState.top + 'px',
        left: toolbarState.left + 'px'
      }}>
        <CommentButton callback={() => hideToolbar()} commentType={CommentType.Comment} />
        <CommentButton callback={() => hideToolbar()} commentType={CommentType.Quote} />
        <Highlighter callback={() => hideToolbar()} />
        <EmojiSelector callback={() => hideToolbar()} />
      </div>,
      document.body)
  )
}

export default Toolbar
