import { CommentInterface } from "interfaces/CommentInterface";

export default (comments: (CommentInterface | undefined)[]) => {
  const _comments = comments.filter(Boolean) as CommentInterface[];
  const ret = {
    top: Infinity, left: Infinity, right: -Infinity, bottom: -Infinity,
    width: -Infinity, height: -Infinity,
  }
  if (!comments.length) {
    return ret;
  }
  _comments.forEach((comment) => {
    const els = [...document.querySelectorAll(`[data-comment-id="${comment.id}"]`)] as Element[];
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;
    els.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const top = rect.y + scrollY;
      const bottom = rect.y + rect.height + scrollY;
      const left = rect.x + scrollX;
      const right = rect.x + rect.width + scrollX;
      if (top < ret.top) {
        ret.top  = top;
      }
      if (left < ret.left) {
        ret.left  = left;
      }
      if (right > ret.right) {
        ret.right = right;
      }
      if (bottom > ret.bottom) {
        ret.bottom = bottom;
      }
      ret.height = ret.bottom - ret.top;
      ret.width = ret.right - ret.left;
    });
  })
  return ret;
}
