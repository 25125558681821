export enum ColorType {
    WHITE = "#ffffff",
    BLACK = "#000000",
    GREEN = "#008000",
    RED = "#ff0000",
    BLUE = "#0000ff",
    BLUEVIOLET = "#8a2be2",
    TEAL = "#008080",
    TRANSPARANT = "transparent",
}

export function clearSelection() {
    if (window.getSelection) {
        if (window.getSelection()?.empty) {  // Chrome
            window.getSelection()?.empty();
        } else if (window.getSelection()?.removeAllRanges) {  // Firefox
            window.getSelection()?.removeAllRanges();
        }
    } else if (document.getSelection) {  // IE?
        document.getSelection()?.empty();
    }
}
