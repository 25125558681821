export interface ITextReference {
    id: string;
    topOffset: number;
    leftOffset?: number;
    value: string;
    parentSelector: string;
    startPos: number;
    location: string;
    endPos: number;
}

export function getDefaultTextReference(): ITextReference {
    return {
        id: "",
        topOffset: 0,
        value: "",
        parentSelector: "",
        startPos: 0,
        location: "",
        endPos: 0,
    }
}
