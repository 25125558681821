import { useCallback, useEffect, useState } from "react";

import { throttle } from "lodash";

const windowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export default () => {
  const [size, setSize] = useState(windowSize());
  const resize = useCallback(throttle(() => {
    setSize(windowSize());
  },200, {
    leading: true,
    trailing: true,
  }), []);
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return size;
}
