import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import cn from 'classnames';
import HighlightedTextContainer from "components/HighlightedTextContainer/HighlightedTextContainer";
import HighlighterToolbar from "components/HighlighterToolbar/HighlighterToolbar";
import ScrollableContainer from "components/ScrollableContainer/ScrollableContainer";
import { rc } from "services/io";
import { AppState, fetchCommentsAction, fetchCommentsIndexThunk, useAppDispatch } from "store";
import { fetchCurrentSessionId } from "store/session";

import TestText from "./components/TestText";
import Toolbar from "./components/Toolbar/Toolbar"

import './components/Helpers/globals';
import './components/Helpers/sha';
import './components/Helpers/commentedId';
import "./styles/App.css"
import "./App.scss";

function App() {
    const {pathname} = useLocation();
    const isDev = pathname.indexOf('/plugin') === 0;
    const dispatch = useAppDispatch();
    const session = useSelector((s: AppState) => s.session);
    const authorizedUser = useSelector<AppState>(s => s.session.authorized)

    useEffect(() => {
      const link = document.createElement('link');
      link.rel="stylesheet";
      link.href="https://fonts.googleapis.com/icon?family=Material+Icons";
      document.head.appendChild(link);
    },[]);

    useEffect(() => {
      if (!session.id && !session.requestIsPending) {
        dispatch(fetchCurrentSessionId())
      }
    }, [session.id, session.requestIsPending])

    useEffect(() => {
      if (session.id) {
        rc.connect(session.id);
        dispatch(fetchCommentsIndexThunk());
        if (authorizedUser) {
          dispatch(fetchCommentsAction());
        }
      }
    }, [session.id]);

    if (!session.id) {
      return <></>
    }

    return (
        <div className={ cn('App','plugin-app',{
            'plugin-dev': isDev
        })}>
            { isDev ? <><TestText/><TestText/></> : <></>}
            <ScrollableContainer/>
            <Toolbar/>
            <HighlighterToolbar/>
            <HighlightedTextContainer/>
        </div>
    );
}

export default App;
