import { useMemo, useRef } from "react";

import CommentForm from "components/CommentForm/CommentForm";
import TextCommentIconsField from "components/TextCommentIconsField/TextCommentIconsField";
import TextCommentsItem from "components/TextCommentsItem/TextCommentsItem"
import useWindowResize from "hooks/useWindowResize";

import "./ScrollableContainer.css"

function ScrollableContainer() {
    const scrollRef = useRef<HTMLDivElement>(null);

    const size = useWindowResize();

    const scrollbarPosition = useMemo(() => {
      const articleBody = document.querySelector(".single-content > .row > div:first-child");
      if (articleBody) {
        return articleBody.getBoundingClientRect().right;
      } else {
        return Infinity;
      }
    }, [size]);

    return (
        <div ref={scrollRef} style={ {
          left: `${scrollbarPosition}px`
        }} className="scrollableContainer">
            <div>
                <CommentForm left={scrollbarPosition}/>
                <TextCommentsItem/>
            </div>
            <div>
                <TextCommentIconsField />
            </div>
        </div>
    );
}

export default ScrollableContainer;
