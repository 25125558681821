import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import classNames from "classnames";
import { ComponentEvents } from "components/Events"
import TextCommentIconItem from "components/TextCommentIconsField/TextCommentIconItem/TextCommentIconItem"
import { CommentInterface, CommentType } from "interfaces/CommentInterface";
import { AppState, removeCommentAction, useAppDispatch} from "store"

const REMEMBER_LS_KEY = '__plugin-remember-comments-buttons-is-active__';
import { SELECTORS } from "components/Helpers/range-serializer";
import $ from 'jquery';
import { HIGHLIGHTED_CLASS } from "vars/consts";

import "./TextCommentIconsField.css"

interface CommentGroup {
    textId: string;
    comments: CommentInterface[],
}

const VERTICAL_GAP = 15;

function TextCommentIconsField() {
    const commentBlockHeight: number = 40 + 5

    const [state, setState] = useState({
      active: JSON.parse(localStorage.getItem(REMEMBER_LS_KEY) ?? 'false')
    });

    useEffect(() => {
      if (state.active) {
        $('body').attr('data-highlight-plugin-active', 'true');
      } else {
        $('body').removeAttr('data-highlight-plugin-active');
      }

      localStorage.setItem(REMEMBER_LS_KEY, state.active);
    }, [state.active]);

    const session = useSelector((state: AppState) => state.session);
    const commentsData: CommentInterface[] = useSelector((state: AppState) => state.comments.comments)
    const {pathname} = useLocation();

    const [topOffsets, setTopOffsets] = useState<number[]>([])
    const [firstInGroup, setFirstInGroup] = useState<boolean[]>([])

    const commentsByPath: CommentInterface[] = useMemo(() => commentsData.filter((c) => c.location === pathname), [commentsData, pathname])
    const commentsByType: CommentInterface[] = useMemo(() => {
        return commentsByPath.filter((c) =>
            c.type === CommentType.Comment || c.type === CommentType.Emoji || (c.type === CommentType.Quote && c.user.login === session.username))
    }, [commentsByPath])

    const commentGroups: CommentGroup[] = useMemo(() => {
        // const commentsByTypeOffsets = {};
        // commentsByType.forEach((c) => {
        //   // const top = $(document.querySelectorAll('[data-comment-id="${firstComment.id}"]')[0]).offset()?.top;
        //   commentsByTypeOffsets[c.id] = $(document.querySelectorAll('[data-comment-id="${firstComment.id}"]')[0]).offset()?.top
        // });
        const sortedComments = commentsByType.sort((x, y) => x.textRefs[0].topOffset - y.textRefs[0].topOffset);
        // const sortedComments = commentsByType.sort((x, y) => commentsByTypeOffsets[x.id] - commentsByTypeOffsets[y.id]);
        let currentTextId = ""
        const result: CommentGroup[] = []
        for (let i = 0; i < sortedComments.length; i++) {
            if (sortedComments[i].textRefs[0].id !== currentTextId) {
                currentTextId = sortedComments[i].textRefs[0].id
                result.push({ textId: currentTextId, comments: [] })
            }
            result[result.length - 1].comments.push(sortedComments[i])
        }
        return result
    }, [commentsByType])

    const changeIconsActivity = useCallback(() => {
        setState(s => ({
          ...s,
          active: !s.active
        }));
        document.dispatchEvent(new CustomEvent(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM));
    }, []);

    const isAuthorized = useSelector((s: AppState) => s.session.authorized);

    const isActive = useMemo(() => {
      return document.querySelectorAll(SELECTORS).length > 0
    }, []) && isAuthorized;

    useEffect(() => {
        const result: number[] = commentGroups.map((g) => g.comments[0].textRefs[0].topOffset)
        const fig: boolean[] = [];
        for (let i = 1; i < result.length; i++) {
            fig[i] = (result[i] - result[i - 1] >= VERTICAL_GAP);
            if (result[i] - result[i - 1] < VERTICAL_GAP) {
                result[i] = result[i - 1] + VERTICAL_GAP;
            }
        }
        setTopOffsets(result);
        setFirstInGroup(fig);
        document.addEventListener(ComponentEvents.UPDATE_COMMENT_ICON_POSITIONS, updatePositions as EventListener)
        window.addEventListener("resize", updatePositions as EventListener)
        return () => {
            document.removeEventListener(ComponentEvents.UPDATE_COMMENT_ICON_POSITIONS, updatePositions as EventListener)
            window.removeEventListener("resize", updatePositions as EventListener)
        }
    }, [commentGroups])

    const updatePositions = useCallback(() => {
        const result: number[] = commentGroups.map((group) => {
            let topOffset: number = group.comments[0].textRefs[0].topOffset
            const element: HTMLElement | null = document.getElementById(group.comments[0].textRefs[0].id)
            if (element !== null) {
                const rect = element.getBoundingClientRect()
                topOffset = rect.top + window.scrollY
            }
            return topOffset
        })
        const fig: boolean[] = [];
        for (let i = 1; i < result.length; i++) {
            fig[i] = (result[i] - result[i - 1] >= VERTICAL_GAP);
            if (result[i] - result[i - 1] < VERTICAL_GAP) {
                result[i] = result[i - 1] + VERTICAL_GAP;
            }
        }
        setTopOffsets(result);
        setFirstInGroup(fig);
    }, [commentGroups])

    if (!isActive) {
      return <></>;
    }

    return (
        <div className={
          classNames(
            "commentContainer",
            { active: state.active }
          )
        } style={{
          right: 0
        }}>
            <button
              className="activityButton buttonContainer"
              onClick={ changeIconsActivity }
            >
              {state.active && <VisibilityIcon />}
              {!state.active && <VisibilityOffIcon />}
            </button>
            <div className="commentIconsContainer">
                {commentGroups.map((commentGroup, index) =>
                    isFinite(topOffsets[index]) ?
                    <TextCommentIconItem
                      key={`${commentGroup.textId}_${index}`}
                      comments={commentGroup.comments}
                      topOffset={topOffsets[index]}
                      zIndex={10 + commentGroups.length - index}
                      firstInGroup={firstInGroup[index]}
                    /> : <></>
                )}
            </div>
        </div>

    );
}

export default TextCommentIconsField;
