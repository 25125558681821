import {sha3_256 as sha} from './sha';

(() => {
  'use strict';
  var WINDOW = typeof window === 'object';
  var root = WINDOW ? window : {};
  if (root.JS_SHA3_NO_WINDOW) {
    WINDOW = false;
  }
  var WEB_WORKER = !WINDOW && typeof self === 'object';
  var NODE_JS = !root.JS_SHA3_NO_NODE_JS && typeof process === 'object' && process.versions && process.versions.node;
  if (NODE_JS) {
    root = global;
  } else if (WEB_WORKER) {
    root = self;
  }

  var methods = {}, methodNames = ['commentedId', 'Buffer', 'React'];
  methods['commentedId'] = () => {
    return sha(''+Math.random() + Date.now() + Math.random());
  };
  for (let i = 0; i < methodNames.length; ++i) {
    root[methodNames[i]] = methods[methodNames[i]];
  }
})();

export default (...args) => {
  return window.commentedId(...args)
}
