import React from "react";

const TestText = () => {
    return (
        <div className="testText">
            <p>
                React (иногда React.js или ReactJS) — JavaScript-библиотека[5]
                с открытым исходным кодом для разработки пользовательских интерфейсов.
                <br /><br />
            </p>
            <p>
                React разрабатывается и поддерживается Facebook,
                Instagram и сообществом отдельных разработчиков и корпораций[6][7][8].
                <br /><br />
            </p>
            <p>
                React может использоваться для разработки одностраничных и мобильных приложений.
                Его цель — предоставить высокую скорость разработки, простоту и масштабируемость.
                В качестве библиотеки для разработки пользовательских интерфейсов
                React часто используется с другими библиотеками, такими как MobX, Redux и GraphQL.
                <br /><br />
            </p>
            <p>
                React использует виртуальный DOM (англ. virtual DOM). React создаёт кэш-структуру в памяти,
                что позволяет вычислять разницу между предыдущим и текущим состояниями интерфейса
                для оптимального обновления DOM браузера. Таким образом программист может работать со страницей,
                считая, что она обновляется вся, но библиотека самостоятельно решает,
                какие компоненты страницы необходимо обновить.
                <br /><br />
            </p>
        </div>
    )
}

export default TestText;
