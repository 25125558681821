import { useCallback,useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";
import classNames from "classnames";
import CommentItem from "components/CommentItem/CommentItem"
import { ComponentEvents } from "components/Events"
import textRefsBoundingRect from "components/Helpers/textRefsBoundingRect";
import useClickOutside from "hooks/useClickOutside";
import useWindowResize from "hooks/useWindowResize";
import { CommentInterface, CommentType} from "interfaces/CommentInterface";
import { isEqual } from "lodash";
import { AppState, useAppDispatch } from "store";
import { updateCommentsResolveStatus } from "store/commentReducer"

import ResponseForm from "./ResponseForm/ResponseForm"

import "./TextCommentsItem.css"

interface ShowDataInterface {
    responseActivity: boolean;
    textId: string;
    topOffset: number;
}

const CLASS_NAME = "textCommentsItem"

function TextCommentsItem() {
    const dispatch = useAppDispatch()
    const boxRef = useRef<HTMLDivElement>(null);
    const [showData, setShowData] = useState<ShowDataInterface>({
        responseActivity: false,
        textId: "",
        topOffset: 0,
    })

    const comments: CommentInterface[] = useSelector((state: AppState) =>
      state.comments.comments.filter((c) => c.textRefs[0].id === showData.textId
    ), (a,b) => {
      const aIds = a.map(c => c.id);
      const bIds = b.map(c => c.id);
      return isEqual(aIds,bIds);
    });

    const onUnwrapComments = useCallback((event: CustomEvent) => {
        setShowData({
            responseActivity: false,
            textId: event.detail.textId,
            topOffset: event.detail.topOffset,
        });
        setTimeout(() => {
          // boxRef.current?.scrollIntoView();
        },1);
    }, [])

    const wrap = useCallback(() => setShowData((s) => ({
      ...s,
      textId: '',
    })), []);

    useClickOutside(() => [boxRef.current], () => {
      wrap();
      document.dispatchEvent(new CustomEvent(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM));
    });

    useEffect(() => {
        document.addEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
        document.addEventListener(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM, wrap as EventListener);
        document.addEventListener(ComponentEvents.CALL_COMMENT_FORM, wrap as EventListener);
        return () => {
            document.removeEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
            document.removeEventListener(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM, wrap as EventListener);
            document.removeEventListener(ComponentEvents.CALL_COMMENT_FORM, wrap as EventListener);
        }
    }, [])

    const hideResponseForm = useCallback(() => {
      setShowData({...showData, responseActivity: false})
    }, [showData])
    const showResponseForm = useCallback(() => {
      setShowData({...showData, responseActivity: true})
    }, [showData])
    const windowSize = useWindowResize();
    const commentTextRefsPos = useMemo(() => {
      return textRefsBoundingRect(comments);
    }, [comments, windowSize]);

    if (!showData.textId || comments.length === 0) {
      return <></>;
    }

    const commentType = comments[0]?.type ?? CommentType.Unknown;

    return (
        <div ref={boxRef} className={CLASS_NAME} id={CLASS_NAME} style={{
            top: `${commentTextRefsPos.top}px`
          }}
        >
            <div style={{"backgroundColor": comments[0]?.resolveStatus? '#90EE90' : '#FFFFFF'}}>
                {
                  commentType === CommentType.Quote && <div className="quote-text">
                    {comments[0]?.textRefs[0]?.value}
                  </div>
                }

                <div className="comments_content">
                    {comments.map((comment: CommentInterface) => <CommentItem key={comment.id} comment={comment}/>)}
                </div>

                {showData.responseActivity && <ResponseForm initComment={comments[0]} callback={hideResponseForm}/>}

                { commentType === CommentType.Comment && <div className="activeButtons">
                    {!showData.responseActivity && <Button onClick={showResponseForm}>Ответить</Button>}
                    {/* <button onClick={changeResolveState}>Resolve</button> */}
                  </div>
                }
            </div>
        </div>
    );
}

export default TextCommentsItem;
