import { useDispatch } from 'react-redux';

import { configureStore, createAction } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';

import comments, { getCommentsInitialState } from './commentReducer';
import session, { getSessionInitialState } from './session';

const reducer = combineReducers({
  session,
  comments,
});

export const restoreStore = createAction('store/restore');
export const clearStore = createAction('store/flush');
export const rootReducer = (state: any, action: AnyAction) => {
  switch (action.type) {
    case clearStore.type: {
      return reducer({
        session: getSessionInitialState(),
        comments: getCommentsInitialState(),
      }, action);
    }
    default: {
      return reducer(state, action);
    }
  }
};
export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    trace: true,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([]),
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

(window as any).appStore = store;

store.subscribe(()=>{
  localStorage.setItem('reduxState', JSON.stringify({
    ...store.getState(),
    session: {
      currentUserId: null,
      requestIsPending: false,
    }
  }))
});
