import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const CURRENT_SESSION_STORAGE_KEY = "pluginSessionData";
export interface SessionData {
  id?: string,
  username: string,
  first_name_en: string;
  last_name_en: string;
  first_name_ru: string;
  last_name_ru: string;
  authorized: boolean;
}
const resetCurrentSessionUser = () => {
  localStorage.removeItem(CURRENT_SESSION_STORAGE_KEY);
}

export interface SessionStoreState extends SessionData {
  requestIsPending: boolean
}

const getCurrentSessionUser = (): Required<SessionData> => {
  const sessionData: SessionData = JSON.parse(localStorage.getItem(CURRENT_SESSION_STORAGE_KEY) ?? '{}');
  sessionData.id = sessionData.id || Math.floor(Math.random()*10000000)+"_"+Date.now();
  sessionData.username = sessionData.username || `user${Math.floor(Math.random()*10000)}`;
  sessionData.first_name_en = sessionData.first_name_en || '';
  sessionData.first_name_ru = sessionData.first_name_ru || '';
  sessionData.last_name_en = sessionData.last_name_en || '';
  sessionData.last_name_ru = sessionData.last_name_ru || '';
  // TODO should be false
  sessionData.authorized = false;
  localStorage.setItem(CURRENT_SESSION_STORAGE_KEY, JSON.stringify(sessionData))
  return sessionData as Required<SessionData>;
}

export const fetchCurrentSessionId = createAsyncThunk<SessionData, void, {
  rejectValue: Error,
}>
(`session/fetchUserId`, async (_, thunkApi) => {
      const req = await fetch('/user-id');
      if (req.ok) {
        const ret = await req.json();
        if (!ret.id) {
          return getCurrentSessionUser();
        } else {
          resetCurrentSessionUser();
        }
        return {
          ...ret,
          authorized: true,
        };
      } else {
        thunkApi.rejectWithValue(new Error(""))
      }
});

export const getSessionInitialState = (): SessionStoreState => {
  return {
    requestIsPending: false,
    username: '',
    first_name_en: '',
    last_name_en: '',
    first_name_ru: '',
    last_name_ru: '',
    authorized: false,
  }
}

const comments = createSlice({
  name: 'session',
  initialState: getSessionInitialState(),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchCurrentSessionId.pending, (state) => {
      state.requestIsPending = true;
    })
    builder.addCase(fetchCurrentSessionId.fulfilled, (state, {payload}) => {
      return {
        ...state,
        ...payload,
        requestIsPending: false,
      }
    })
  },
});

export default comments.reducer;
// export const {

// } = comments.actions;
