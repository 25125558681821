import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom"
import { useSelector } from "react-redux";

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from "classnames";
import { ComponentEvents } from "components/Events"
import { ColorType } from "components/Helpers/highlight"
import textRefsBoundingRect from "components/Helpers/textRefsBoundingRect";
import Highlighter, { HighlightColor, TextBackColor, TextColor } from "components/Toolbar/Highlighter/Highlighter";
import { backgroundColors, textColors } from "consts/colors";
import useClickOutside from "hooks/useClickOutside";
import useWindowResize from "hooks/useWindowResize";
import { CommentInterface } from "interfaces/CommentInterface";
import { AppState, removeCommentAction, useAppDispatch } from "store";
import { updateCommentAction } from "store/commentReducer"

import "./HighlighterToolbar.css"

interface State {
    commentId: string;
    selectorIsOpened: boolean;
    htmlElement?: HTMLElement;
    textColor: string;
    backColor: string;
}

function HighlighterToolbar() {
    const dispatch = useAppDispatch()
    const boxRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<State>({
        commentId: '',
        selectorIsOpened: false,
        textColor: ColorType.WHITE.toString(),
        backColor: ColorType.TRANSPARANT.toString(),
    });

    const comment = useSelector<AppState>(s => s.comments.comments.find(c => c.id === state.commentId)) as CommentInterface | undefined;

    const windowSize = useWindowResize();

    const boxRefs = useCallback(() => [boxRef.current],[]);
    useClickOutside(boxRefs, () => {
      hide();
    });

    const removeComment = useCallback(() => {
      if (comment) {
        hide()
        dispatch(removeCommentAction(comment));
      }
    }, [comment])

    useEffect(() => {
        document.addEventListener(ComponentEvents.SHOW_HIGHLIGHTER_TOOLBAR, onShow as EventListener);
        return () => {
            document.removeEventListener(ComponentEvents.SHOW_HIGHLIGHTER_TOOLBAR, onShow as EventListener);
        }
    }, [])

    useEffect(() => {
        const newValue = `${state.textColor}_${state.backColor}`;
        if (comment?.id && comment.value !== newValue) {
            dispatch(updateCommentAction({
              ...comment,
              value: newValue
            }));
            if (state.htmlElement?.style.color !== undefined) {
                state.htmlElement.style.color = state.textColor
            }
            if (state.htmlElement?.style.backgroundColor !== undefined) {
                state.htmlElement.style.backgroundColor = state.backColor
            }
        }
    }, [state, comment])

    const onShow = useCallback((event: CustomEvent) => {
        document.querySelector(".highlighterToolbar")?.classList.add("active")

        const comment: CommentInterface = event.detail.comment
        const colors: string[] = comment.value.split('_')

        setState({
            commentId: comment.id,
            selectorIsOpened: false,
            htmlElement: event.detail.htmlElement,
            textColor: colors[0],
            backColor: colors[1],
        });
    }, []);

    const hide = useCallback(() => {
      setState(s => ({
        ...s,
        commentId: '',
    }));
    }, []);

    const commentTextRefsPos = useMemo(() => {
      return textRefsBoundingRect([comment]);
    }, [comment, windowSize]);

    useClickOutside(() => [boxRef.current], () => {
      hide();
    })
    const colorSelectorRef = useRef<HTMLDivElement>(null);
    useClickOutside(() => [colorSelectorRef.current], () => setState(s => ({
      ...s,
      selectorIsOpened: false
    })));

    if (!comment) {
      return <></>;
    }

    // console.log(state);
//     <div>
            //         {textColors.map((colorType, index) => <TextColor key={index} callback={() => setState({...state, textColor: colorType.toString()})} textColor={colorType}/>)}
            //     </div>
            //     <div>
            //         {backgroundColors.map((colorType, index) => <TextBackColor key={index} callback={() => setState({...state, backColor: colorType.toString()})} backColor={colorType}/>)}
            //     </div>
            //     <button onClick={removeComment}>Удалить</button>
            //     <button onClick={hide}>Закрыть</button>
            // </div>

    return (
        createPortal(
            <div ref={boxRef} className={classNames("highlighter-toolbar", {
              active: !!state.commentId,
            })} style={{
              top: (commentTextRefsPos.top - 40) + 'px',
              left: (commentTextRefsPos.left + commentTextRefsPos.width - 90) + 'px'
            }}>

              <div className={classNames("highlighter", {
                active: state.selectorIsOpened
              })}>
                  <div className="highlighter-value">
                      <div className='value' style={{
                          backgroundColor: state.backColor,
                          color: state.textColor
                        }}>
                          A
                      </div>
                      <div className="chevron" onClick={() => setState(s => ({...s, selectorIsOpened: !s.selectorIsOpened }))}>
                        <ExpandMoreIcon/>
                      </div>
                  </div>
                  {
                    state.selectorIsOpened && <div className="highlighter-selector">
                      <div ref={colorSelectorRef}>
                          <h6>Текст</h6>
                          {textColors.map((colorType, index) =>
                            <TextColor key={index} callback={() => setState( s => ({ ...s, textColor: colorType, selectorIsOpened: false }))} textColor={colorType} />)}
                          <h6>Фон</h6>
                          {backgroundColors.map((colorType, index) =>
                            <TextBackColor key={index} callback={() => setState( s => ({ ...s, backColor: colorType, selectorIsOpened: false }))} backColor={colorType} />)}
                        </div>
                    </div>
                  }
              </div>
              <div className='highlighter-remove' onClick={removeComment}>
                <DeleteIcon />
              </div>
              <div className='highlighter-close' onClick={hide}>
                <CloseIcon />
              </div>
            </div>
            ,
            document.body
        )
    )
}

export default HighlighterToolbar
