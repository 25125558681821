import { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { ComponentEvents } from "components/Events"
import { CommentInterface, CommentType } from "interfaces/CommentInterface"
import { ITextReference } from "interfaces/ITextReference"
import { AppState } from "store"
import { HIGHLIGHTED_CLASS } from "vars/consts"

import "./HighlightedTextContainer.css"

interface ITextBlock {
    type: CommentType;
    value: string;
    startPos: number;
    endPos: number;
    text: string;
    textId: string;
    commentId: string;
}

function HighlightedTextContainer() {
    const { pathname } = useLocation()
    // const session = useSelector((state))
    const comments: CommentInterface[] = useSelector((state: AppState) => state.comments.comments)
    const user = useSelector((s: AppState) => s.session.username);
    const commentsByPath: CommentInterface[] = useMemo(() => {
        const ret = comments.filter((c) => c.location === pathname).filter((c) => {
          return c.type === CommentType.Emoji || c.type === CommentType.Comment || c.user.login === user
        })
        ret.sort((a,b) => (new Date(a.created) > new Date(b.created) ? 1 : -1));
        return ret;
    }, [comments, pathname])

    useEffect(() => {
        updateHighlight(commentsByPath)
    }, [commentsByPath])

    const updateHighlight = useCallback((comments: CommentInterface[]) => {
        const elements = [...document.getElementsByClassName(HIGHLIGHTED_CLASS)] as Element[];
        elements.sort((a,b) => parseInt(b.id) - parseInt(a.id));
        unhighlight(elements)
        getSingleTextRefs(comments).forEach(highlight);
    }, [])

    const unhighlight = useCallback((elements: Element[]) => {
        const updatedParentNodes: HTMLElement[] = []
        for (let i = 0; i < elements.length; i++) {
            const higlightToRemove = elements[i]
            const parentNode: HTMLElement = higlightToRemove.parentNode as HTMLElement
            parentNode.insertBefore(document.createTextNode(higlightToRemove.innerHTML), higlightToRemove)
            updatedParentNodes.push(parentNode)
            higlightToRemove.remove()
        }
        [... new Set(updatedParentNodes)].map((pn) => pn.normalize())
    }, [])

    const getSingleTextRefs = useCallback((comments: CommentInterface[]): [CommentInterface, ITextReference][][] => {
        const singleTextBlockRefs: [CommentInterface, ITextReference][] = []
        for (let i = 0; i < comments.length; i++) {
            for (let j = 0; j < comments[i].textRefs.length; j++) {
                singleTextBlockRefs.push([comments[i], comments[i].textRefs[j]])
            }
        }
        // #TODO dictioanry??
        const textRefsSelectors: string[] = []
        const result: [CommentInterface, ITextReference][][] = []
        for (let i = 0; i < singleTextBlockRefs.length; i++) {
            const textRef = singleTextBlockRefs[i][1]
            const index = textRefsSelectors.indexOf(textRef.parentSelector)
            if (index === -1) {
                textRefsSelectors.push(textRef.parentSelector)
                result.push([singleTextBlockRefs[i]])
            } else {
                result[index].push(singleTextBlockRefs[i])
            }
        }
        return result
    }, [])

    const highlight = useCallback((singleTextRefs: [CommentInterface, ITextReference][]) => {
        const comments: CommentInterface[] = singleTextRefs.map((item) => item[0])
        const textRefs: ITextReference[] = singleTextRefs.map((item) => item[1])

        const parentNode = document.querySelector(textRefs[0].parentSelector)
        const parentElement = parentNode as HTMLElement

        const textBlocks: ITextBlock[] = divideTextIntoBlocks(parentElement, comments, textRefs)

        for (let i = textBlocks.length - 1; i > -1; i--) {
            const textBlock = textBlocks[i]
            const [textNodeText, posShift]: [Text, number] = getStartTextNode(parentElement, textBlock.startPos)
            if (textNodeText === null) {
                continue
            }

            const nextNode = textNodeText.splitText(textBlock.endPos + 1 - posShift)
            const nodeToRemove = textNodeText.splitText(textBlock.startPos - posShift)

            const textElement = setupTextElementByType(textBlock, comments.find(c => c.id === textBlock.commentId))

            parentNode?.appendChild(textElement)
            parentElement.insertBefore(textElement, nextNode)
            nodeToRemove.remove()
        }
        document.dispatchEvent(new CustomEvent(ComponentEvents.UPDATE_COMMENT_ICON_POSITIONS))
    }, [])

    const divideTextIntoBlocks = useCallback((parentElement: HTMLElement, comments: CommentInterface[], textRefs: ITextReference[]): ITextBlock[] => {
        const commentIndexes = new Array(parentElement.innerText.length + 1)
        for (let i = 0; i < commentIndexes.length; i++) {
            commentIndexes[i] = -1
        }
        for (let i = 0; i < textRefs.length; i++) {
            for (let j = textRefs[i].startPos; j < textRefs[i].endPos; j++) {
                commentIndexes[j] = i
            }
        }

        const textBlocks: ITextBlock[] = []
        let currentIndex = -1
        for (let i = 0; i < commentIndexes.length; i++) {
            if (commentIndexes[i] !== currentIndex) {
                if (commentIndexes[i] === -1) {
                    continue
                }
                currentIndex = commentIndexes[i]
                textBlocks.push({
                    startPos: i,
                    endPos: 0,
                    type: comments[currentIndex].type,
                    value: comments[currentIndex].value,
                    text: "",
                    textId: textRefs[currentIndex].id,
                    commentId: comments[currentIndex].id
                })
            }
            if (currentIndex !== -1) {
                textBlocks[textBlocks.length - 1].endPos = i
            }
        }

        for (let i = 0; i < textBlocks.length; i++) {
            const textBlock = textBlocks[i]
            if (parentElement.textContent !== null) {
                textBlock.text = parentElement.textContent.substring(textBlock.startPos, textBlock.endPos + 1)
            }
        }
        return textBlocks
    }, [])

    const getStartTextNode = useCallback((parentElement: HTMLElement, startPos: number): [Text, number] => {
        const childNodes: Node[] = [...parentElement.childNodes].map((cn) => cn as Node)
        let posShift = 0
        let startShift = 0
        let childIndex = 0
        for (let i = 0; i < childNodes.length; i++) {
            const textContentLength = childNodes[i].textContent?.length
            posShift = startShift
            startShift += textContentLength != undefined ? textContentLength : 0
            if (startShift - startPos > 0) {
                childIndex = i
                break
            }
        }
        return [childNodes[childIndex] as Text, posShift]
    }, [])

    const setupTextElementByType = useCallback((textBlock: ITextBlock, comment: CommentInterface | undefined): HTMLElement => {
      const textElement: HTMLElement = document.createElement("span");
      textElement.id = textBlock.textId
      textElement.className = HIGHLIGHTED_CLASS;
      textElement.setAttribute('data-comment-id', comment?.id ?? '');
      textElement.setAttribute('data-comment-type', comment?.type ?? CommentType.Unknown);
      textElement.textContent = textBlock.text
      textElement.onpointerenter = () => {
        document.dispatchEvent(new CustomEvent(ComponentEvents.SELECTION_ENTER, {
          detail: { textId: textBlock.textId, commentId: comment?.id ?? '' }
        }));
      }
      textElement.onpointerleave = () => {
        document.dispatchEvent(new CustomEvent(ComponentEvents.SELECTION_LEAVE, {
          detail: { textId: textBlock.textId, commentId: comment?.id ?? '' }
        }));
      }

      switch (textBlock.type) {
          case CommentType.Comment:
          case CommentType.Quote:
          case CommentType.Emoji:
              textElement.onclick = (ev) => {
                  document.dispatchEvent(new CustomEvent(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, {
                      detail: { textId: textBlock.textId, topOffset: null }
                  }));
                  ev.stopPropagation();
              }
              break
          case CommentType.Highlight: {
              textElement.onclick = (ev) => {
                  document.dispatchEvent(new CustomEvent(ComponentEvents.SHOW_HIGHLIGHTER_TOOLBAR, {
                      detail: { comment: comment, htmlElement: textElement }
                  }))
                  ev.stopPropagation();
              }
              const colors: string[] = textBlock.value.split('_');
              textElement.style.color = colors[0]
              textElement.style.backgroundColor = colors[1]
              break;
          }
          default:
              console.log("No handler for", textBlock.type)
              break
      }
      return textElement
    }, []);

    return (
        <div className="highlightedTextContainer"></div>
    );
}

export default HighlightedTextContainer;
