import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import classNames from "classnames";
import { ComponentEvents } from "components/Events"
import { CommentInterface, CommentType } from "interfaces/CommentInterface";
import { AppState } from "store";

import "./TextCommentIconItem.css"

function TextCommentIconItem({
  comments,
  topOffset,
  zIndex,
  firstInGroup = true
}: {
  comments: CommentInterface[],
  topOffset: number,
  zIndex: number,
  firstInGroup: boolean
}) {
    const [state, setState] = useState({
      unwrapped: false,
      hovered: false,
    });
    const authorizedUser = useSelector((a: AppState) => a.session.authorized);
    const changeState = useCallback((status: boolean) => {
      const firstComment = comments[0];
      if (!firstComment) {
        return;
      }
      switch(firstComment.type) {
        case CommentType.Comment:
        case CommentType.Quote: {
          document.dispatchEvent(new CustomEvent(
            status ? ComponentEvents.WRAP_TEXT_COMMENTS_ITEM : ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM,
            { detail: { textId: comments[0].textRefs[0]?.id, topOffset: topOffset }}
          ))
          break;
        }
      }
    }, [comments])
    const hoverState = useRef<boolean>(false);
    const onPointerEnter = useCallback(() => {
      if (hoverState.current) {
        return;
      }
      const firstComment = comments[0];
      if (!firstComment) {
        return;
      }
      hoverState.current = true;
      const selections = document.querySelectorAll(`[data-comment-id="${firstComment.id}"]`);
      selections.forEach((e) => e.classList.add('hover'));
      setState((s) => ({
        ...s,
        hovered: true
      }));
    }, [comments[0]]);
    const onPointerLeave = useCallback(() => {
      setState((s) => ({
        ...s,
        hovered: false
      }));
      if (!hoverState.current) {
        return;
      }
      const firstComment = comments[0];
      if (!firstComment) {
        return;
      }
      hoverState.current = false;
      const selections = document.querySelectorAll(`[data-comment-id="${firstComment.id}"]`);
      selections.forEach((e) => e.classList.remove('hover'));
    }, [comments[0]]);

    const onSelectionEnter = useCallback(({detail: {commentId}}) => {
      const firstComment = comments[0];
      if (commentId !== firstComment?.id) {
        return;
      }
      const selections = document.querySelectorAll(`[data-comment-id="${firstComment.id}"]`);
      selections.forEach((e) => e.classList.add('hover'));
      setState((s) => ({
        ...s,
        hovered: true
      }));
    }, [comments]);

    const onSelectionLeave = useCallback(({detail: {commentId}}) => {
      const firstComment = comments[0];
      if (commentId !== firstComment.id) {
        return;
      }
      const selections = document.querySelectorAll(`[data-comment-id="${firstComment.id}"]`);
      selections.forEach((e) => e.classList.remove('hover'));
      setState((s) => ({
        ...s,
        hovered: false
      }));
    }, [comments]);

    const onWrapComments = useCallback(() => {
      const selections = document.querySelectorAll(`[data-comment-id]`);
      selections.forEach((e) => e.classList.remove('unwrapped'));
      setState((s) => ({
        ...s,
        unwrapped: false,
      }));
    }, [])

    const onUnwrapComments = useCallback(({detail: {textId}}) => {
      const firstComment = comments[0];
      if (firstComment?.textRefs[0]?.id === textId) {
        const selections = document.querySelectorAll(`[data-comment-id="${firstComment.id}"]`);
        selections.forEach((e) => e.classList.add('unwrapped'));
      }
      setState((s) => ({
        ...s,
        unwrapped: textId === firstComment?.textRefs[0]?.id
      }));
    }, [])

    useEffect(() => {
      // @ts-ignore
        document.addEventListener(ComponentEvents.SELECTION_ENTER, onSelectionEnter as EventListener);
        // @ts-ignore
        document.addEventListener(ComponentEvents.SELECTION_LEAVE, onSelectionLeave as EventListener);
        // @ts-ignore
        document.addEventListener(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM, onWrapComments as EventListener);
        // @ts-ignore
        document.addEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
        return () => {
          // @ts-ignore
            document.removeEventListener(ComponentEvents.SELECTION_ENTER, onSelectionEnter as EventListener);
            // @ts-ignore
            document.removeEventListener(ComponentEvents.SELECTION_LEAVE, onSelectionLeave as EventListener);
            // @ts-ignore
            document.removeEventListener(ComponentEvents.WRAP_TEXT_COMMENTS_ITEM, onWrapComments as EventListener);
            // @ts-ignore
            document.removeEventListener(ComponentEvents.UNWRAP_TEXT_COMMENTS_ITEM, onUnwrapComments as EventListener);
        }
    }, [])

    const pointerLeave = useCallback(() => {}, []);

    if (!authorizedUser || comments.length === 0) {
      return <></>
    }
    const firstComment = comments[0];
    return (
        <div onPointerEnter={onPointerEnter}
             onPointerLeave={onPointerLeave}
             className="textCommentIconItem"
             style={{
              top: (state.hovered && !state.unwrapped && !firstInGroup ? topOffset + 20 : topOffset),
              zIndex: (state.unwrapped ? 100000 : zIndex ),
             }}
        >
          <button className={classNames("buttonContainer", {
              unwrapped: state.unwrapped,
              hovered: state.hovered,
            })}
            onClick={() => changeState(state.unwrapped)}
          >
            {firstComment.type === CommentType.Comment && <MessageOutlinedIcon className={classNames('mui-icon',{
              inverted: state.unwrapped
            })}/>}
            {firstComment.type === CommentType.Quote && <FormatQuoteIcon className={classNames('mui-icon',{
              inverted: state.unwrapped
            })}/>}
            {firstComment.type === CommentType.Emoji && <span>{firstComment.value}</span>}

            {/* {comments.length} */}
          </button>
        </div>
    );
}

export default TextCommentIconItem;
